import { Grid } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { RoutalLoader, RoutalTheme } from '@nx-smartmonkey/ui';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RawIntlProvider } from 'react-intl';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import darkIcon from '../../favicon_apple.png';
import lightIcon from '../../favicon_apple_white.png';
import * as routing from '../Routing';
import { CustomersLayout } from '../components/CustomersLayout';
import { SnackbarComponent } from '../components/SnackbarComponent';
import { intl } from '../helpers/IntGlobalProvider';
import { useRetrieveProject } from '../hooks/project/useRetrieveProject';
import { retrieveNavigatorLanguage, useRetrieveTranslation } from '../hooks/translations/useRetrieveTranslation';
import { CustomersExample } from './CustomersExample';
import { CustomersStop } from './CustomersStop';

export const Customers = () => {
  const { activeLanguage, isLoading: isRetrievingTranslation, run: retrieveTranslation } = useRetrieveTranslation();
  const { data: project, error: projectError } = useRetrieveProject();
  const [defaultIntl, setDefaultIntl] = useState(intl);

  useEffect(() => {
    if (activeLanguage) {
      setDefaultIntl(activeLanguage);
    }
  }, [activeLanguage]);

  if (projectError) {
    window.open(`https://routal.com/404`, `_self`);
  }

  const [faviconHref, setFaviconHref] = useState(darkIcon);

  /**
   * Returns the path for a favicon based on the given color scheme.
   *
   * @param {boolean} isDarkMode If currently in dark mode
   */
  const getFaviconPath = (isDarkMode = false) => {
    if (!isDarkMode) return darkIcon;
    else return lightIcon;
  };

  useEffect(() => {
    // Get current color scheme.
    const matcher = window.matchMedia(`(prefers-color-scheme: dark)`);
    // Set favicon initially.
    setFaviconHref(getFaviconPath(matcher.matches));
    // Change favicon if the color scheme changes.
    matcher.onchange = () => setFaviconHref(getFaviconPath(matcher.matches));
  }, [faviconHref]);

  if (!activeLanguage && !isRetrievingTranslation) {
    const language = retrieveNavigatorLanguage();
    retrieveTranslation({ language });
  }

  return (
    <ThemeProvider theme={RoutalTheme}>
      <RawIntlProvider value={defaultIntl}>
        <Helmet defaultTitle="Customers" title={project?.style?.page_title}>
          <link rel="icon" type="image/png" href={project?.style?.favicon ?? faviconHref} />
        </Helmet>

        <SnackbarComponent />
        {!activeLanguage ? (
          <CustomersLayout bodyStyles={{ justifyContent: `center` }}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ height: `100%` }}
              wrap="nowrap"
            >
              <Grid item container alignItems="center" justifyContent="center">
                <RoutalLoader color={project?.style?.primary_color?.value} />
              </Grid>
            </Grid>
          </CustomersLayout>
        ) : (
          <CustomersLayout>
            <Router>
              <Routes>
                <Route path={routing.CUSTOMERS_STOP} element={<CustomersStop />} />
                <Route path={routing.EXAMPLE} element={<CustomersExample />} />
                <Route path="*" element={<CustomersStop />} />
              </Routes>
            </Router>
          </CustomersLayout>
        )}
      </RawIntlProvider>
    </ThemeProvider>
  );
};
